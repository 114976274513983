*,
*:before,
*:after {
  box-sizing: border-box;
}

p {
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.3;
}
